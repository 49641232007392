body {
    color: #797979;
    background: #ffffff;
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #092a49;
    font-family: 'Oswald', sans-serif;
}

a {
    color: #454545;
    transition: .3s;
}

a:hover,
a:active,
a:focus {
    color: #092a49;
    outline: none;
    text-decoration: none;
}

.btn:focus,
.form-control:focus {
    box-shadow: none;
}

.container-fluid {
    max-width: 1366px;
}

.back-to-top {
    position: fixed;
    display: none;
    background: #0796fe;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 22px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 9;
}

.back-to-top i {
    color: #092a49;
    padding-top: 10px;
}

.back-to-top:hover {
    background: #092a49;
}

.back-to-top:hover i {
    color: #0796fe;
}

@media (min-width: 992px) {
    .mt-125 {
        margin-top: 125px;
    }
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
    position: relative;
    height: 35px;
    background-image: linear-gradient(to right, #0796fe, #092a49);
}

.top-bar .top-bar-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.top-bar .top-bar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.top-bar .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 35px;
    padding: 0 10px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text:last-child {
    border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text i {
    font-size: 14px;
    color: #ffffff;
    margin-right: 5px;
}

.top-bar .text h2 {
    color: #eeeeee;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0;
}

.top-bar .text p {
    color: #eeeeee;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
}

.top-bar .social {
    display: flex;
    height: 35px;
    font-size: 0;
    justify-content: flex-end;
}

.top-bar .social a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 100%;
    font-size: 16px;
    color: #ffffff;
    border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:first-child {
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:hover {
    color: #092a49;
    background: #ffffff;
}

@media (min-width: 992px) {
    .top-bar {
        padding: 0 60px;
    }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.navbar {
    position: relative;
    transition: .5s;
    z-index: 999;
}

.navbar.nav-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transition: .5s;
}

.navbar .navbar-brand {
    margin: 0;
    color: #ffffff;
    font-size: 45px;
    line-height: 0;
    font-weight: 200;
    text-transform: uppercase;
}

.navbar .navbar-brand img {
    max-width: 100%;
    max-height: 40px;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    padding: 10px 10px 8px 10px;
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: #0796fe;
    transition: none;
}

.navbar .dropdown-menu {
    margin-top: 0;
    border: 0;
    border-radius: 0;
    background: #f8f9fa;
}

@media (min-width: 992px) {
    .navbar {
        position: absolute;
        width: 100%;
        top: 35px;
        padding: 20px 60px;
        background: rgba(9, 42, 73, .2) !important;
        z-index: 9;
    }

    .navbar.nav-sticky {
        padding: 10px 60px;
        background: #092a49 !important;
    }

    .page .navbar {
        background: #092a49 !important;
    }

    .navbar a.nav-link {
        padding: 8px 15px;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

@media (max-width: 992px) {
    .navbar {
        padding: 15px;
        background: #092a49 !important;
    }

    .navbar a.nav-link {
        padding: 5px;
    }

    .navbar .dropdown-menu {
        box-shadow: none;
    }
}


/*******************************/
/******* Section Header ********/
/*******************************/

.section-header {
    position: relative;
    text-align: left;
    margin-bottom: 45px;
}

.section-header p {
    margin-bottom: 5px;
    position: relative;
    font-size: 16px;
}

.section-header h2 {
    position: relative;
    font-size: 50px;
    font-weight: 200;
}

@media (max-width: 992px) {
    .section-header h2 {
        font-size: 45px;
    }
}

@media (max-width: 768px) {
    .section-header h2 {
        font-size: 40px;
    }
}

@media (max-width: 576px) {
    .section-header h2 {
        font-size: 35px;
    }
}

/*****************************/
/***** Services Page CSS *****/
/*****************************/

.service-page {
    position: relative;
    width: 100%;
    text-align: center;
}

.service-page .section-header {
    margin-bottom: 30px;
    margin-left: 0;
    text-align: center;
}

.service-page .service-img {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.service-page .service-img img {
    width: 50%;
}

.service-page .service-text p {
    font-size: 16px;
}

.service-page .service-text a.btn {
    position: relative;
    margin-top: 15px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #092a49;
    border: 2px solid #092a49;
    border-radius: 0;
    background: none;
    transition: .3s;
}

.service-page .service-text a.btn:hover {
    color: #0796fe;
    background: #092a49;
}

@media (max-width: 768px) {
    .service-page .service-img {
        margin-bottom: 30px;
        height: auto;
    }
}

/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.service .service-item {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    padding: 30px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
    transition: .3s;
}

.service .service-item:hover {
    border-color: transparent;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.service .service-item img {
    max-height: 50px;
    margin-bottom: 20px;
}

.service .service-item h3 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
}

.service .service-item p {
    margin-bottom: 10px;
    font-size: 16px;
}

.service .service-item a {
    position: relative;
    font-size: 16px;
    color: #0796fe;
    transition: .3s;
}

.service .service-item a::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    bottom: -3px;
    left: 10%;
    background: #0796fe;
}

.service .service-item:hover a.btn {
    color: #092a49;
    background: #1d2434;
    border-color: #1d2434;
}

/*******************************/
/******* Single Page CSS *******/
/*******************************/

.single {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.single img {
    width: 100%;
    margin: 0 0 25px 0;
}

.single .table,
.single .list-group {
    margin-bottom: 30px;
}

.single .ul-group {
    padding: 0;
    list-style: none;
}

.single .ul-group li {
    margin-left: 2px;
}

.single .ul-group li::before {
    position: relative;
    content: "\f0da";
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    margin-right: 10px;
}

.single .ol-group {
    padding-left: 18px;
}

/*******************************/
/********* Footer CSS **********/
/*******************************/


.footer {
    position: relative;
    margin-top: 45px;
    padding-top: 90px;
    background: #092a49;
}

@media screen and (max-width: 768px) {
    .footer .footer-centered {
        text-align: center;
    }
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-newsletter {
    position: relative;
    margin-bottom: 45px;
    color: #ffffff;
}

.footer .footer-contact h2,
.footer .footer-link h2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #ffffff;
}

@media screen and (min-width: 768px) {
    .footer .footer-contact h2::after,
    .footer .footer-link h2::after {
        position: absolute;
        content: "";
        width: 45px;
        height: 2px;
        bottom: 0;
        left: 0;
        background: #0796fe;
    }
}


.footer .footer-link a {
    display: block;
    margin-bottom: 10px;
    color: #ffffff;
    transition: .3s;
}

.footer .footer-link a::before {
    position: relative;
    font-family: serif;
    font-weight: 900;
    margin-right: 10px;
}

.footer .footer-link a:hover {
    color: #0796fe;
    letter-spacing: 1px;
}

.footer .footer-contact p i {
    width: 25px;
}


.footer .copyright {
    padding: 0 30px;
}

.footer .copyright .row {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright p {
    margin: 0;
    color: #999999;
}

.footer .copyright .col-md-6:last-child p {
    text-align: right;
}

.footer .copyright p a {
    color: #ffffff;
}

.footer .copyright p a:hover {
    color: #0796fe;
}

@media (max-width: 768px) {
    .footer .copyright p,
    .footer .copyright .col-md-6:last-child p {
        margin: 5px 0;
        text-align: center;
    }
}

/*******************************/
/******** Carousel CSS *********/
/*******************************/

.carousel .carousel-img {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: right;
    overflow: hidden;
}

.carousel .carousel-img::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(9, 42, 73, .7);
    z-index: 1;
}

.carousel .carousel-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel .carousel-text {
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .carousel .carousel-item {
        height: 100vh;
    }

    .carousel .carousel-text h5 {
        text-align: center;
        color: #ffffff;
        font-size: 70px;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .carousel .carousel-text p {
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 768px) {
    .carousel .carousel-item {
        height: 90vh;
    }

    .carousel .carousel-text h5 {
        text-align: center;
        color: #ffffff;
        font-size: 40px;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .carousel .carousel-text p {
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        margin-bottom: 25px;
    }
}

.carousel .carousel-text .btn {
    padding: 15px 30px;
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
    background: none;
    border: 2px solid #ffffff;
    border-radius: 0;
    transition: .3s;
}

.carousel .carousel-text .btn i {
    margin-right: 5px;
}

.carousel .carousel-text .btn:hover {
    color: #0796fe;
    background: #ffffff;
}

.carousel .carousel-btn .btn:first-child {
    margin-right: 15px;
}

/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #ffffff;
    border-radius: 50px;
    text-align: center;
    padding-left: 2px;
    font-size: 37px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 60px;
        height: 60px;
        bottom: 25px;
        right: 25px;
        padding-left: 2px;
        font-size: 37px;
    }
}